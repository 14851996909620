:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #07bc0c;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e74c3c;
  --toastify-color-transparent: #ffffffb3;
  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);
  --toastify-toast-width: 320px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;
  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;
  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;
  --toastify-color-progress-light: linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55);
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}

.Toastify__toast-container {
  z-index: var(--toastify-z-index);
  -webkit-transform: translate3d(0, 0, var(--toastify-z-index) px);
  width: var(--toastify-toast-width);
  box-sizing: border-box;
  color: #fff;
  padding: 4px;
  position: fixed;
}

.Toastify__toast-container--top-left {
  top: 1em;
  left: 1em;
}

.Toastify__toast-container--top-center {
  top: 1em;
  left: 50%;
  transform: translateX(-50%);
}

.Toastify__toast-container--top-right {
  top: 1em;
  right: 1em;
}

.Toastify__toast-container--bottom-left {
  bottom: 1em;
  left: 1em;
}

.Toastify__toast-container--bottom-center {
  bottom: 1em;
  left: 50%;
  transform: translateX(-50%);
}

.Toastify__toast-container--bottom-right {
  bottom: 1em;
  right: 1em;
}

@media only screen and (width <= 480px) {
  .Toastify__toast-container {
    width: 100vw;
    margin: 0;
    padding: 0;
    left: 0;
  }

  .Toastify__toast-container--top-left, .Toastify__toast-container--top-center, .Toastify__toast-container--top-right {
    top: 0;
    transform: translateX(0);
  }

  .Toastify__toast-container--bottom-left, .Toastify__toast-container--bottom-center, .Toastify__toast-container--bottom-right {
    bottom: 0;
    transform: translateX(0);
  }

  .Toastify__toast-container--rtl {
    right: 0;
    left: initial;
  }
}

.Toastify__toast {
  min-height: var(--toastify-toast-min-height);
  box-sizing: border-box;
  max-height: var(--toastify-toast-max-height);
  font-family: var(--toastify-font-family);
  cursor: pointer;
  z-index: 0;
  direction: ltr;
  border-radius: 4px;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 8px;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 1px 10px #0000001a, 0 2px 15px #0000000d;
}

.Toastify__toast--rtl {
  direction: rtl;
}

.Toastify__toast-body {
  flex: auto;
  align-items: center;
  margin: auto 0;
  padding: 6px;
  display: flex;
}

.Toastify__toast-body > div:last-child {
  flex: 1;
}

.Toastify__toast-icon {
  -webkit-margin-end: 10px;
  flex-shrink: 0;
  width: 20px;
  margin-inline-end: 10px;
  display: flex;
}

.Toastify--animate {
  animation-duration: .7s;
  animation-fill-mode: both;
}

.Toastify--animate-icon {
  animation-duration: .3s;
  animation-fill-mode: both;
}

@media only screen and (width <= 480px) {
  .Toastify__toast {
    border-radius: 0;
    margin-bottom: 0;
  }
}

.Toastify__toast-theme--dark {
  background: var(--toastify-color-dark);
  color: var(--toastify-text-color-dark);
}

.Toastify__toast-theme--light, .Toastify__toast-theme--colored.Toastify__toast--default {
  background: var(--toastify-color-light);
  color: var(--toastify-text-color-light);
}

.Toastify__toast-theme--colored.Toastify__toast--info {
  color: var(--toastify-text-color-info);
  background: var(--toastify-color-info);
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  color: var(--toastify-text-color-success);
  background: var(--toastify-color-success);
}

.Toastify__toast-theme--colored.Toastify__toast--warning {
  color: var(--toastify-text-color-warning);
  background: var(--toastify-color-warning);
}

.Toastify__toast-theme--colored.Toastify__toast--error {
  color: var(--toastify-text-color-error);
  background: var(--toastify-color-error);
}

.Toastify__progress-bar-theme--light {
  background: var(--toastify-color-progress-light);
}

.Toastify__progress-bar-theme--dark {
  background: var(--toastify-color-progress-dark);
}

.Toastify__progress-bar--info {
  background: var(--toastify-color-progress-info);
}

.Toastify__progress-bar--success {
  background: var(--toastify-color-progress-success);
}

.Toastify__progress-bar--warning {
  background: var(--toastify-color-progress-warning);
}

.Toastify__progress-bar--error {
  background: var(--toastify-color-progress-error);
}

.Toastify__progress-bar-theme--colored.Toastify__progress-bar--info, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--success, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
  background: var(--toastify-color-transparent);
}

.Toastify__close-button {
  color: #fff;
  cursor: pointer;
  opacity: .7;
  background: none;
  border: none;
  outline: none;
  align-self: flex-start;
  padding: 0;
  transition: all .3s;
}

.Toastify__close-button--light {
  color: #000;
  opacity: .3;
}

.Toastify__close-button > svg {
  fill: currentColor;
  width: 14px;
  height: 16px;
}

.Toastify__close-button:hover, .Toastify__close-button:focus {
  opacity: 1;
}

@keyframes Toastify__trackProgress {
  0% {
    transform: scaleX(1);
  }

  100% {
    transform: scaleX(0);
  }
}

.Toastify__progress-bar {
  z-index: var(--toastify-z-index);
  opacity: .7;
  transform-origin: 0;
  width: 100%;
  height: 5px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.Toastify__progress-bar--animated {
  animation: linear forwards Toastify__trackProgress;
}

.Toastify__progress-bar--controlled {
  transition: transform .2s;
}

.Toastify__progress-bar--rtl {
  right: 0;
  left: initial;
  transform-origin: 100%;
}

.Toastify__spinner {
  box-sizing: border-box;
  border: 2px solid;
  border-color: var(--toastify-spinner-color-empty-area);
  border-right-color: var(--toastify-spinner-color);
  border-radius: 100%;
  width: 20px;
  height: 20px;
  animation: .65s linear infinite Toastify__spin;
}

@keyframes Toastify__bounceInRight {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }

  75% {
    transform: translate3d(10px, 0, 0);
  }

  90% {
    transform: translate3d(-5px, 0, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}

@keyframes Toastify__bounceInLeft {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }

  75% {
    transform: translate3d(-10px, 0, 0);
  }

  90% {
    transform: translate3d(5px, 0, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}

@keyframes Toastify__bounceInUp {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }

  75% {
    transform: translate3d(0, 10px, 0);
  }

  90% {
    transform: translate3d(0, -5px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}

@keyframes Toastify__bounceInDown {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0);
  }

  75% {
    transform: translate3d(0, -10px, 0);
  }

  90% {
    transform: translate3d(0, 5px, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}

.Toastify__bounce-enter--top-left, .Toastify__bounce-enter--bottom-left {
  animation-name: Toastify__bounceInLeft;
}

.Toastify__bounce-enter--top-right, .Toastify__bounce-enter--bottom-right {
  animation-name: Toastify__bounceInRight;
}

.Toastify__bounce-enter--top-center {
  animation-name: Toastify__bounceInDown;
}

.Toastify__bounce-enter--bottom-center {
  animation-name: Toastify__bounceInUp;
}

.Toastify__bounce-exit--top-left, .Toastify__bounce-exit--bottom-left {
  animation-name: Toastify__bounceOutLeft;
}

.Toastify__bounce-exit--top-right, .Toastify__bounce-exit--bottom-right {
  animation-name: Toastify__bounceOutRight;
}

.Toastify__bounce-exit--top-center {
  animation-name: Toastify__bounceOutUp;
}

.Toastify__bounce-exit--bottom-center {
  animation-name: Toastify__bounceOutDown;
}

@keyframes Toastify__zoomIn {
  from {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes Toastify__zoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  to {
    opacity: 0;
  }
}

.Toastify__zoom-enter {
  animation-name: Toastify__zoomIn;
}

.Toastify__zoom-exit {
  animation-name: Toastify__zoomOut;
}

@keyframes Toastify__flipIn {
  from {
    opacity: 0;
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, 90deg);
  }

  40% {
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, -20deg);
  }

  60% {
    opacity: 1;
    transform: perspective(400px)rotate3d(1, 0, 0, 10deg);
  }

  80% {
    transform: perspective(400px)rotate3d(1, 0, 0, -5deg);
  }

  to {
    transform: perspective(400px);
  }
}

@keyframes Toastify__flipOut {
  from {
    transform: perspective(400px);
  }

  30% {
    opacity: 1;
    transform: perspective(400px)rotate3d(1, 0, 0, -20deg);
  }

  to {
    opacity: 0;
    transform: perspective(400px)rotate3d(1, 0, 0, 90deg);
  }
}

.Toastify__flip-enter {
  animation-name: Toastify__flipIn;
}

.Toastify__flip-exit {
  animation-name: Toastify__flipOut;
}

@keyframes Toastify__slideInRight {
  from {
    visibility: visible;
    transform: translate3d(110%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideInLeft {
  from {
    visibility: visible;
    transform: translate3d(-110%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideInUp {
  from {
    visibility: visible;
    transform: translate3d(0, 110%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideInDown {
  from {
    visibility: visible;
    transform: translate3d(0, -110%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideOutRight {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(110%, 0, 0);
  }
}

@keyframes Toastify__slideOutLeft {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(-110%, 0, 0);
  }
}

@keyframes Toastify__slideOutDown {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, 500px, 0);
  }
}

@keyframes Toastify__slideOutUp {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, -500px, 0);
  }
}

.Toastify__slide-enter--top-left, .Toastify__slide-enter--bottom-left {
  animation-name: Toastify__slideInLeft;
}

.Toastify__slide-enter--top-right, .Toastify__slide-enter--bottom-right {
  animation-name: Toastify__slideInRight;
}

.Toastify__slide-enter--top-center {
  animation-name: Toastify__slideInDown;
}

.Toastify__slide-enter--bottom-center {
  animation-name: Toastify__slideInUp;
}

.Toastify__slide-exit--top-left, .Toastify__slide-exit--bottom-left {
  animation-name: Toastify__slideOutLeft;
}

.Toastify__slide-exit--top-right, .Toastify__slide-exit--bottom-right {
  animation-name: Toastify__slideOutRight;
}

.Toastify__slide-exit--top-center {
  animation-name: Toastify__slideOutUp;
}

.Toastify__slide-exit--bottom-center {
  animation-name: Toastify__slideOutDown;
}

@keyframes Toastify__spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: funkRocker-regular;
  src: url("funkrocker.regular.d91af515.otf");
}

@font-face {
  font-family: raleWay-regular;
  src: url("Raleway-Regular.8259b5d9.ttf");
}

@font-face {
  font-family: sfPro-bold;
  src: url("SFProDisplay-Bold.e7878ac8.ttf");
}

@font-face {
  font-family: sfPro-regular;
  src: url("SFProDisplay-Regular.3c8d453e.ttf");
}

@font-face {
  font-family: sfPro-medium;
  src: url("sfprodisplaymedium.00d4b6d2.OTF");
}

@font-face {
  font-family: showGathic-regular;
  src: url("Showcargothic.13396f48.ttf");
}

.noScrollbar::-webkit-scrollbar {
  display: none;
}

.noScrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

#tap::placeholder {
  color: #fff;
  opacity: .6;
}

#tap::-moz-placeholder {
  color: #fff;
}

.inputbase {
  line-height: 0 !important;
}

img {
  object-fit: contain;
}

@layer textTyping {
  .dynamic-txt {
    height: 70px;
    font-size: 50px;
    line-height: 70px;
    overflow: hidden;
  }

  .dynamic-txt > li {
    list-style: none;
    animation: 6s steps(2, end) infinite slide;
    position: relative;
  }

  @keyframes slide {
    100% {
      top: -70px;
    }
  }

  .dynamic-txt li span:after {
    content: "|";
    background: #141531;
    width: 100%;
    height: 100%;
    line-height: 70px;
    animation: 6s steps(10, end) infinite typing;
    position: absolute;
    left: 0;
  }

  @keyframes typing {
    40%, 60% {
      left: calc(100% + 30px);
    }

    100% {
      left: 0;
    }
  }
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.scrollbar-hide {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.loader-container {
  z-index: 100;
  justify-content: center;
  align-items: center;
  height: 45vh;
  display: flex;
  position: absolute;
  left: 43%;
}

.loader {
  border: 4px solid #0000001a;
  border-left-color: #ffa91a;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: 1s linear infinite spin;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.textareas {
  color: red;
}

.textareas::placeholder {
  color: red;
  content: "here";
}

textarea::placeholder {
  color: #fff;
}

.drop-shadow img {
  filter: drop-shadow(0 0 5px #ffa91a);
}

.nextbtn:hover {
  box-shadow: (0px 0px 10px 5px #e3a031);
}

.burn-start-btn {
  animation: 3s infinite zoom-in-zoom-out;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

.cursor-animation {
  opacity: 1;
  animation: .5s linear infinite cursor-animation;
}

@keyframes cursor-animation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.cursor-animation:after {
  content: " |";
}

@keyframes zoomInFromBack {
  0% {
    opacity: 0;
    transform: translateZ(-2000px)scale(.1);
  }

  100% {
    opacity: 1;
    transform: translateZ(0)scale(1);
  }
}

.hiddenStage {
  opacity: 0;
  transform: translateZ(-2000px)scale(.1);
}

.animate-zoom-in {
  animation: 3s ease-in-out forwards zoomInFromBack;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: Helvetica Neue;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
}

@media (width >= 640px) {
  .container {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .container {
    max-width: 1536px;
  }
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.inset-0 {
  inset: 0;
}

.-right-4 {
  right: -1rem;
}

.-top-6 {
  top: -1.5rem;
}

.-top-8 {
  top: -2rem;
}

.bottom-0 {
  bottom: 0;
}

.bottom-4 {
  bottom: 1rem;
}

.bottom-5 {
  bottom: 1.25rem;
}

.bottom-8 {
  bottom: 2rem;
}

.bottom-\[1\%\] {
  bottom: 1%;
}

.bottom-\[16\%\] {
  bottom: 16%;
}

.bottom-\[1px\] {
  bottom: 1px;
}

.bottom-\[30vw\] {
  bottom: 30vw;
}

.left-0 {
  left: 0;
}

.left-\[1\%\] {
  left: 1%;
}

.left-\[2\%\] {
  left: 2%;
}

.left-\[48\%\] {
  left: 48%;
}

.right-0 {
  right: 0;
}

.right-16 {
  right: 4rem;
}

.right-2 {
  right: .5rem;
}

.right-4 {
  right: 1rem;
}

.right-\[-3px\] {
  right: -3px;
}

.right-\[10\%\] {
  right: 10%;
}

.right-\[2\%\] {
  right: 2%;
}

.right-\[30px\] {
  right: 30px;
}

.top-0 {
  top: 0;
}

.top-1 {
  top: .25rem;
}

.top-5 {
  top: 1.25rem;
}

.top-\[-14px\] {
  top: -14px;
}

.top-\[-22px\] {
  top: -22px;
}

.top-\[10\%\] {
  top: 10%;
}

.top-\[10px\] {
  top: 10px;
}

.top-\[11\%\] {
  top: 11%;
}

.top-\[17\%\] {
  top: 17%;
}

.top-\[20\%\] {
  top: 20%;
}

.top-\[200px\] {
  top: 200px;
}

.top-\[25\%\] {
  top: 25%;
}

.top-\[30\%\] {
  top: 30%;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-30 {
  z-index: 30;
}

.z-40 {
  z-index: 40;
}

.z-50 {
  z-index: 50;
}

.z-\[100\] {
  z-index: 100;
}

.z-\[999\] {
  z-index: 999;
}

.float-right {
  float: right;
}

.m-2 {
  margin: .5rem;
}

.m-\[18px\] {
  margin: 18px;
}

.m-\[1px\] {
  margin: 1px;
}

.m-auto {
  margin: auto;
}

.mx-1 {
  margin-left: .25rem;
  margin-right: .25rem;
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.mx-3 {
  margin-left: .75rem;
  margin-right: .75rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.my-\[10px\] {
  margin-top: 10px;
  margin-bottom: 10px;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-\[10px\] {
  margin-bottom: 10px;
}

.mb-\[20px\] {
  margin-bottom: 20px;
}

.mb-\[5px\] {
  margin-bottom: 5px;
}

.ml-0 {
  margin-left: 0;
}

.ml-1 {
  margin-left: .25rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-20 {
  margin-left: 5rem;
}

.ml-3 {
  margin-left: .75rem;
}

.ml-7 {
  margin-left: 1.75rem;
}

.ml-\[10\%\] {
  margin-left: 10%;
}

.ml-\[10px\] {
  margin-left: 10px;
}

.ml-\[14px\] {
  margin-left: 14px;
}

.ml-\[25\%\] {
  margin-left: 25%;
}

.ml-\[5\.5\%\] {
  margin-left: 5.5%;
}

.mr-1 {
  margin-right: .25rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mr-5 {
  margin-right: 1.25rem;
}

.mr-8 {
  margin-right: 2rem;
}

.mr-\[17\%\] {
  margin-right: 17%;
}

.mt-0 {
  margin-top: 0;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-7 {
  margin-top: 1.75rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-\[-96px\] {
  margin-top: -96px;
}

.mt-\[10px\] {
  margin-top: 10px;
}

.mt-\[15px\] {
  margin-top: 15px;
}

.mt-\[20\%\] {
  margin-top: 20%;
}

.mt-\[6px\] {
  margin-top: 6px;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-10 {
  height: 2.5rem;
}

.h-12 {
  height: 3rem;
}

.h-14 {
  height: 3.5rem;
}

.h-16 {
  height: 4rem;
}

.h-20 {
  height: 5rem;
}

.h-28 {
  height: 7rem;
}

.h-3 {
  height: .75rem;
}

.h-3\.5 {
  height: .875rem;
}

.h-32 {
  height: 8rem;
}

.h-4 {
  height: 1rem;
}

.h-5 {
  height: 1.25rem;
}

.h-52 {
  height: 13rem;
}

.h-6 {
  height: 1.5rem;
}

.h-7 {
  height: 1.75rem;
}

.h-8 {
  height: 2rem;
}

.h-9 {
  height: 2.25rem;
}

.h-\[100\%\] {
  height: 100%;
}

.h-\[100px\] {
  height: 100px;
}

.h-\[100vh\] {
  height: 100vh;
}

.h-\[10px\] {
  height: 10px;
}

.h-\[10vh\] {
  height: 10vh;
}

.h-\[150px\] {
  height: 150px;
}

.h-\[20\%\] {
  height: 20%;
}

.h-\[200px\] {
  height: 200px;
}

.h-\[20px\] {
  height: 20px;
}

.h-\[30\%\] {
  height: 30%;
}

.h-\[30px\] {
  height: 30px;
}

.h-\[31px\] {
  height: 31px;
}

.h-\[320px\] {
  height: 320px;
}

.h-\[340px\] {
  height: 340px;
}

.h-\[35px\] {
  height: 35px;
}

.h-\[360px\] {
  height: 360px;
}

.h-\[40px\] {
  height: 40px;
}

.h-\[50px\] {
  height: 50px;
}

.h-\[50vh\] {
  height: 50vh;
}

.h-\[60\%\] {
  height: 60%;
}

.h-\[60px\] {
  height: 60px;
}

.h-\[65\%\] {
  height: 65%;
}

.h-\[66\%\] {
  height: 66%;
}

.h-\[70\%\] {
  height: 70%;
}

.h-\[72px\] {
  height: 72px;
}

.h-\[75\%\] {
  height: 75%;
}

.h-\[80\%\] {
  height: 80%;
}

.h-\[80vh\] {
  height: 80vh;
}

.h-\[89\%\] {
  height: 89%;
}

.h-\[90\%\] {
  height: 90%;
}

.h-\[9px\] {
  height: 9px;
}

.h-fit {
  height: fit-content;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.min-h-\[100px\] {
  min-height: 100px;
}

.min-h-\[110px\] {
  min-height: 110px;
}

.min-h-\[160px\] {
  min-height: 160px;
}

.min-h-\[300px\] {
  min-height: 300px;
}

.min-h-\[320px\] {
  min-height: 320px;
}

.min-h-\[331px\] {
  min-height: 331px;
}

.min-h-\[360px\] {
  min-height: 360px;
}

.min-h-\[380px\] {
  min-height: 380px;
}

.min-h-\[400px\] {
  min-height: 400px;
}

.min-h-\[50px\] {
  min-height: 50px;
}

.min-h-\[600px\] {
  min-height: 600px;
}

.min-h-\[620px\] {
  min-height: 620px;
}

.min-h-\[80px\] {
  min-height: 80px;
}

.min-h-full {
  min-height: 100%;
}

.min-h-screen {
  min-height: 100vh;
}

.w-0 {
  width: 0;
}

.w-1\/2 {
  width: 50%;
}

.w-10 {
  width: 2.5rem;
}

.w-12 {
  width: 3rem;
}

.w-14 {
  width: 3.5rem;
}

.w-16 {
  width: 4rem;
}

.w-20 {
  width: 5rem;
}

.w-24 {
  width: 6rem;
}

.w-28 {
  width: 7rem;
}

.w-3\/5 {
  width: 60%;
}

.w-32 {
  width: 8rem;
}

.w-4 {
  width: 1rem;
}

.w-4\/5 {
  width: 80%;
}

.w-48 {
  width: 12rem;
}

.w-5 {
  width: 1.25rem;
}

.w-52 {
  width: 13rem;
}

.w-6 {
  width: 1.5rem;
}

.w-7 {
  width: 1.75rem;
}

.w-8 {
  width: 2rem;
}

.w-9 {
  width: 2.25rem;
}

.w-\[100\%\] {
  width: 100%;
}

.w-\[100px\] {
  width: 100px;
}

.w-\[120px\] {
  width: 120px;
}

.w-\[13\%\] {
  width: 13%;
}

.w-\[140px\] {
  width: 140px;
}

.w-\[180px\] {
  width: 180px;
}

.w-\[18vw\] {
  width: 18vw;
}

.w-\[19vw\] {
  width: 19vw;
}

.w-\[20px\] {
  width: 20px;
}

.w-\[21\%\] {
  width: 21%;
}

.w-\[21vw\] {
  width: 21vw;
}

.w-\[258px\] {
  width: 258px;
}

.w-\[30\%\] {
  width: 30%;
}

.w-\[31px\] {
  width: 31px;
}

.w-\[35px\] {
  width: 35px;
}

.w-\[40\%\] {
  width: 40%;
}

.w-\[40px\] {
  width: 40px;
}

.w-\[5\%\] {
  width: 5%;
}

.w-\[50\%\] {
  width: 50%;
}

.w-\[50px\] {
  width: 50px;
}

.w-\[57\%\] {
  width: 57%;
}

.w-\[60\%\] {
  width: 60%;
}

.w-\[60vw\] {
  width: 60vw;
}

.w-\[65\%\] {
  width: 65%;
}

.w-\[68\%\] {
  width: 68%;
}

.w-\[7\%\] {
  width: 7%;
}

.w-\[70\%\] {
  width: 70%;
}

.w-\[72\%\] {
  width: 72%;
}

.w-\[75\%\] {
  width: 75%;
}

.w-\[79\%\] {
  width: 79%;
}

.w-\[80\%\] {
  width: 80%;
}

.w-\[85\%\] {
  width: 85%;
}

.w-\[86\%\] {
  width: 86%;
}

.w-\[8vw\] {
  width: 8vw;
}

.w-\[90\%\] {
  width: 90%;
}

.w-\[90px\] {
  width: 90px;
}

.w-\[92\%\] {
  width: 92%;
}

.w-\[95\%\] {
  width: 95%;
}

.w-\[9px\] {
  width: 9px;
}

.w-\[9vw\] {
  width: 9vw;
}

.w-full {
  width: 100%;
}

.min-w-0 {
  min-width: 0;
}

.min-w-\[100px\] {
  min-width: 100px;
}

.min-w-\[210px\] {
  min-width: 210px;
}

.min-w-\[64vw\] {
  min-width: 64vw;
}

.min-w-\[70px\] {
  min-width: 70px;
}

.min-w-\[720px\] {
  min-width: 720px;
}

.min-w-\[72vw\] {
  min-width: 72vw;
}

.max-w-3xl {
  max-width: 48rem;
}

.max-w-7xl {
  max-width: 80rem;
}

.max-w-screen-2xl {
  max-width: 1536px;
}

.origin-top-right {
  transform-origin: 100% 0;
}

.-rotate-90 {
  --tw-rotate: -90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-skew-y-6 {
  --tw-skew-y: -6deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-150 {
  --tw-scale-x: 1.5;
  --tw-scale-y: 1.5;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes blink {
  0%, 100% {
    border-color: #fff;
  }

  50% {
    border-color: #fff;
  }
}

.animate-blink {
  animation: 2s infinite blink;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-pointer {
  cursor: pointer;
}

.resize-none {
  resize: none;
}

.list-\[\'◉\'\] {
  list-style-type: "◉";
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.gap-0 {
  gap: 0;
}

.gap-1 {
  gap: .25rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-5 {
  gap: 1.25rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-x-\[5\%\] {
  column-gap: 5%;
}

.gap-y-\[10\%\] {
  row-gap: 10%;
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.self-end {
  align-self: flex-end;
}

.self-center {
  align-self: center;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.overscroll-x-contain {
  overscroll-behavior-x: contain;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.overflow-ellipsis, .text-ellipsis {
  text-overflow: ellipsis;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.whitespace-pre {
  white-space: pre;
}

.text-wrap {
  text-wrap: wrap;
}

.text-nowrap {
  text-wrap: nowrap;
}

.text-balance {
  text-wrap: balance;
}

.break-words {
  overflow-wrap: break-word;
}

.rounded {
  border-radius: .25rem;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-\[10px\] {
  border-radius: 10px;
}

.rounded-\[20px\] {
  border-radius: 20px;
}

.rounded-\[50\%\] {
  border-radius: 50%;
}

.rounded-\[5px\] {
  border-radius: 5px;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-sm {
  border-radius: .125rem;
}

.rounded-xl {
  border-radius: .75rem;
}

.rounded-b {
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.rounded-b-md {
  border-bottom-right-radius: .375rem;
  border-bottom-left-radius: .375rem;
}

.rounded-b-xl {
  border-bottom-right-radius: .75rem;
  border-bottom-left-radius: .75rem;
}

.rounded-l-3xl {
  border-top-left-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;
}

.rounded-t {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.rounded-t-md {
  border-top-left-radius: .375rem;
  border-top-right-radius: .375rem;
}

.rounded-t-xl {
  border-top-left-radius: .75rem;
  border-top-right-radius: .75rem;
}

.border {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-4 {
  border-width: 4px;
}

.border-8 {
  border-width: 8px;
}

.border-\[2px\] {
  border-width: 2px;
}

.border-\[3px\] {
  border-width: 3px;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-b-\[1px\] {
  border-bottom-width: 1px;
}

.border-l-2 {
  border-left-width: 2px;
}

.border-dashed {
  border-style: dashed;
}

.border-\[\#A137DF\] {
  --tw-border-opacity: 1;
  border-color: rgb(161 55 223 / var(--tw-border-opacity));
}

.border-\[\#D9C3AF\] {
  --tw-border-opacity: 1;
  border-color: rgb(217 195 175 / var(--tw-border-opacity));
}

.border-\[\#E051CF\] {
  --tw-border-opacity: 1;
  border-color: rgb(224 81 207 / var(--tw-border-opacity));
}

.border-\[\#FE8125\] {
  --tw-border-opacity: 1;
  border-color: rgb(254 129 37 / var(--tw-border-opacity));
}

.border-\[\#FF3821\] {
  --tw-border-opacity: 1;
  border-color: rgb(255 56 33 / var(--tw-border-opacity));
}

.border-\[\#FFA103\] {
  --tw-border-opacity: 1;
  border-color: rgb(255 161 3 / var(--tw-border-opacity));
}

.border-\[\#FFA91A\] {
  --tw-border-opacity: 1;
  border-color: rgb(255 169 26 / var(--tw-border-opacity));
}

.border-\[\#c91d12\] {
  --tw-border-opacity: 1;
  border-color: rgb(201 29 18 / var(--tw-border-opacity));
}

.border-\[\#e3a031\] {
  --tw-border-opacity: 1;
  border-color: rgb(227 160 49 / var(--tw-border-opacity));
}

.border-\[rgb\(255\,169\,26\)\] {
  --tw-border-opacity: 1;
  border-color: rgb(255 169 26 / var(--tw-border-opacity));
}

.border-\[white\] {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.border-red {
  --tw-border-opacity: 1;
  border-color: rgb(209 0 40 / var(--tw-border-opacity));
}

.border-b-bgMain {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(20 21 49 / var(--tw-border-opacity));
}

.border-b-black {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(0 0 0 / var(--tw-border-opacity));
}

.bg-Akaroa {
  --tw-bg-opacity: 1;
  background-color: rgb(217 195 175 / var(--tw-bg-opacity));
}

.bg-\[\#452E26\] {
  --tw-bg-opacity: 1;
  background-color: rgb(69 46 38 / var(--tw-bg-opacity));
}

.bg-\[\#A54AF4\] {
  --tw-bg-opacity: 1;
  background-color: rgb(165 74 244 / var(--tw-bg-opacity));
}

.bg-\[\#DFDFDF\] {
  --tw-bg-opacity: 1;
  background-color: rgb(223 223 223 / var(--tw-bg-opacity));
}

.bg-\[\#FD4F15\] {
  --tw-bg-opacity: 1;
  background-color: rgb(253 79 21 / var(--tw-bg-opacity));
}

.bg-\[\#FFA91A\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 169 26 / var(--tw-bg-opacity));
}

.bg-\[\#e3a031\] {
  --tw-bg-opacity: 1;
  background-color: rgb(227 160 49 / var(--tw-bg-opacity));
}

.bg-\[darkgreen\] {
  --tw-bg-opacity: 1;
  background-color: rgb(0 100 0 / var(--tw-bg-opacity));
}

.bg-\[red\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 0 0 / var(--tw-bg-opacity));
}

.bg-\[rgba\(0\,0\,0\,0\.5\)\] {
  background-color: #00000080;
}

.bg-\[white\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-bgBlack {
  --tw-bg-opacity: 1;
  background-color: rgb(29 28 26 / var(--tw-bg-opacity));
}

.bg-bgGreen {
  --tw-bg-opacity: 1;
  background-color: rgb(22 216 81 / var(--tw-bg-opacity));
}

.bg-bgMain {
  --tw-bg-opacity: 1;
  background-color: rgb(20 21 49 / var(--tw-bg-opacity));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-black\/80 {
  background-color: #000c;
}

.bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity));
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.bg-rd {
  --tw-bg-opacity: 1;
  background-color: rgb(249 32 40 / var(--tw-bg-opacity));
}

.bg-red {
  --tw-bg-opacity: 1;
  background-color: rgb(209 0 40 / var(--tw-bg-opacity));
}

.bg-textBlack {
  --tw-bg-opacity: 1;
  background-color: rgb(60 63 98 / var(--tw-bg-opacity));
}

.bg-transparent {
  background-color: #0000;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-yelo {
  --tw-bg-opacity: 1;
  background-color: rgb(253 223 153 / var(--tw-bg-opacity));
}

.bg-opacity-60 {
  --tw-bg-opacity: .6;
}

.bg-opacity-90 {
  --tw-bg-opacity: .9;
}

.bg-opacity-95 {
  --tw-bg-opacity: .95;
}

.bg-bg_burn {
  background-image: url("bg_burn.d000f530.svg");
}

.bg-flames {
  background-image: url("flames.20064d0b.svg");
}

.bg-cover {
  background-size: cover;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.bg-repeat-round {
  background-repeat: round;
}

.p-0 {
  padding: 0;
}

.p-0\.5 {
  padding: .125rem;
}

.p-1 {
  padding: .25rem;
}

.p-14 {
  padding: 3.5rem;
}

.p-2 {
  padding: .5rem;
}

.p-3 {
  padding: .75rem;
}

.p-4 {
  padding: 1rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-8 {
  padding: 2rem;
}

.p-\[10px\] {
  padding: 10px;
}

.p-\[3px\] {
  padding: 3px;
}

.p-\[5px\] {
  padding: 5px;
}

.p-\[5vw\] {
  padding: 5vw;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.px-1\.5 {
  padding-left: .375rem;
  padding-right: .375rem;
}

.px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.px-\[10px\] {
  padding-left: 10px;
  padding-right: 10px;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-1\.5 {
  padding-top: .375rem;
  padding-bottom: .375rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-7 {
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-1 {
  padding-bottom: .25rem;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pb-5 {
  padding-bottom: 1.25rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pb-\[35px\] {
  padding-bottom: 35px;
}

.pl-1 {
  padding-left: .25rem;
}

.pl-2 {
  padding-left: .5rem;
}

.pl-3 {
  padding-left: .75rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pl-\[30px\] {
  padding-left: 30px;
}

.pl-\[35px\] {
  padding-left: 35px;
}

.pr-1 {
  padding-right: .25rem;
}

.pr-1\.5 {
  padding-right: .375rem;
}

.pr-2 {
  padding-right: .5rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pr-5 {
  padding-right: 1.25rem;
}

.pt-0 {
  padding-top: 0;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-end {
  text-align: end;
}

.align-middle {
  vertical-align: middle;
}

.font-\[\'funkRocker-regular\'\], .font-\[funkRocker-regular\] {
  font-family: funkRocker-regular;
}

.font-\[raleWay-regular\] {
  font-family: raleWay-regular;
}

.font-\[sfPro-bold\] {
  font-family: sfPro-bold;
}

.font-\[sfPro-medium\] {
  font-family: sfPro-medium;
}

.font-\[sfPro-regular\] {
  font-family: sfPro-regular;
}

.font-\[sfPro\] {
  font-family: sfPro;
}

.font-\[showGathic-regular\] {
  font-family: showGathic-regular;
}

.font-mono {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-\[1\.5rem\] {
  font-size: 1.5rem;
}

.text-\[11px\] {
  font-size: 11px;
}

.text-\[12px\] {
  font-size: 12px;
}

.text-\[12vw\] {
  font-size: 12vw;
}

.text-\[13px\] {
  font-size: 13px;
}

.text-\[14px\] {
  font-size: 14px;
}

.text-\[15px\] {
  font-size: 15px;
}

.text-\[16\.5px\] {
  font-size: 16.5px;
}

.text-\[16px\] {
  font-size: 16px;
}

.text-\[17px\] {
  font-size: 17px;
}

.text-\[18px\] {
  font-size: 18px;
}

.text-\[19px\] {
  font-size: 19px;
}

.text-\[20px\] {
  font-size: 20px;
}

.text-\[22px\] {
  font-size: 22px;
}

.text-\[23px\] {
  font-size: 23px;
}

.text-\[2rem\] {
  font-size: 2rem;
}

.text-\[30px\] {
  font-size: 30px;
}

.text-\[36px\] {
  font-size: 36px;
}

.text-\[3rem\] {
  font-size: 3rem;
}

.text-\[40px\] {
  font-size: 40px;
}

.text-\[5rem\] {
  font-size: 5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-\[600\] {
  font-weight: 600;
}

.font-\[700\] {
  font-weight: 700;
}

.font-\[800\] {
  font-weight: 800;
}

.font-bold {
  font-weight: 700;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.italic {
  font-style: italic;
}

.tracking-wide {
  letter-spacing: .025em;
}

.text-\[\#FD4F15\] {
  --tw-text-opacity: 1;
  color: rgb(253 79 21 / var(--tw-text-opacity));
}

.text-\[\#FFA91A\] {
  --tw-text-opacity: 1;
  color: rgb(255 169 26 / var(--tw-text-opacity));
}

.text-\[\#c91d12\] {
  --tw-text-opacity: 1;
  color: rgb(201 29 18 / var(--tw-text-opacity));
}

.text-\[\#e03757\] {
  --tw-text-opacity: 1;
  color: rgb(224 55 87 / var(--tw-text-opacity));
}

.text-\[600\] {
  color: 600;
}

.text-\[wheat\] {
  --tw-text-opacity: 1;
  color: rgb(245 222 179 / var(--tw-text-opacity));
}

.text-bgBlack {
  --tw-text-opacity: 1;
  color: rgb(29 28 26 / var(--tw-text-opacity));
}

.text-bgGreen {
  --tw-text-opacity: 1;
  color: rgb(22 216 81 / var(--tw-text-opacity));
}

.text-bgMain {
  --tw-text-opacity: 1;
  color: rgb(20 21 49 / var(--tw-text-opacity));
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-blue-500 {
  --tw-text-opacity: 1;
  color: rgb(59 130 246 / var(--tw-text-opacity));
}

.text-blue-600 {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity));
}

.text-green-600 {
  --tw-text-opacity: 1;
  color: rgb(22 163 74 / var(--tw-text-opacity));
}

.text-rd {
  --tw-text-opacity: 1;
  color: rgb(249 32 40 / var(--tw-text-opacity));
}

.text-red {
  --tw-text-opacity: 1;
  color: rgb(209 0 40 / var(--tw-text-opacity));
}

.text-textOrange {
  --tw-text-opacity: 1;
  color: rgb(255 169 26 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-yelo {
  --tw-text-opacity: 1;
  color: rgb(253 223 153 / var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.opacity-100 {
  opacity: 1;
}

.opacity-30 {
  opacity: .3;
}

.opacity-50 {
  opacity: .5;
}

.opacity-60 {
  opacity: .6;
}

.opacity-70 {
  opacity: .7;
}

.opacity-75 {
  opacity: .75;
}

.opacity-80 {
  opacity: .8;
}

.opacity-90 {
  opacity: .9;
}

.opacity-\[0\.8\] {
  opacity: .8;
}

.shadow-3xl {
  --tw-shadow: 0 0 30px #0000004d;
  --tw-shadow-colored: 0 0 30px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[\#D9C3AF\]\/60 {
  --tw-shadow-color: #d9c3af99;
  --tw-shadow: var(--tw-shadow-colored);
}

.outline-none {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.drop-shadow {
  --tw-drop-shadow: drop-shadow(0 1px 2px #0000001a) drop-shadow(0 1px 1px #0000000f);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.delay-150 {
  transition-delay: .15s;
}

.duration-150 {
  transition-duration: .15s;
}

.duration-300 {
  transition-duration: .3s;
}

.duration-500 {
  transition-duration: .5s;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.ease-linear {
  transition-timing-function: linear;
}

.hover\:w-\[85px\]:hover {
  width: 85px;
}

.hover\:scale-105:hover {
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:cursor-default:hover {
  cursor: default;
}

.hover\:cursor-not-allowed:hover {
  cursor: not-allowed;
}

.hover\:cursor-pointer:hover {
  cursor: pointer;
}

.hover\:rounded-full:hover {
  border-radius: 9999px;
}

.hover\:border-\[3px\]:hover {
  border-width: 3px;
}

.hover\:border-\[\#FFA91A\]:hover {
  --tw-border-opacity: 1;
  border-color: rgb(255 169 26 / var(--tw-border-opacity));
}

.hover\:bg-\[\#FFA91A\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 169 26 / var(--tw-bg-opacity));
}

.hover\:bg-\[\#d3d3d3\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(211 211 211 / var(--tw-bg-opacity));
}

.hover\:bg-blue-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity));
}

.hover\:bg-gray-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.hover\:bg-white:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.hover\:text-black:hover {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.hover\:text-purple-100:hover {
  --tw-text-opacity: 1;
  color: rgb(243 232 255 / var(--tw-text-opacity));
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

.focus\:border-blue-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(59 130 246 / var(--tw-border-opacity));
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.group:hover .group-hover\:block {
  display: block;
}

@media not all and (width >= 768px) {
  .max-md\:block {
    display: block;
  }
}

@media (width >= 640px) {
  .sm\:bottom-6 {
    bottom: 1.5rem;
  }

  .sm\:bottom-\[17vw\] {
    bottom: 17vw;
  }

  .sm\:bottom-\[5\%\] {
    bottom: 5%;
  }

  .sm\:left-0 {
    left: 0;
  }

  .sm\:left-\[30\%\] {
    left: 30%;
  }

  .sm\:mb-3 {
    margin-bottom: .75rem;
  }

  .sm\:ml-4 {
    margin-left: 1rem;
  }

  .sm\:ml-\[1\.5rem\] {
    margin-left: 1.5rem;
  }

  .sm\:ml-\[12px\] {
    margin-left: 12px;
  }

  .sm\:mt-0 {
    margin-top: 0;
  }

  .sm\:mt-\[-89px\] {
    margin-top: -89px;
  }

  .sm\:mt-\[10px\] {
    margin-top: 10px;
  }

  .sm\:mt-\[5vw\] {
    margin-top: 5vw;
  }

  .sm\:block {
    display: block;
  }

  .sm\:inline {
    display: inline;
  }

  .sm\:flex {
    display: flex;
  }

  .sm\:h-\[34vh\] {
    height: 34vh;
  }

  .sm\:h-\[35vh\] {
    height: 35vh;
  }

  .sm\:h-\[82\%\] {
    height: 82%;
  }

  .sm\:w-16 {
    width: 4rem;
  }

  .sm\:w-60 {
    width: 15rem;
  }

  .sm\:w-64 {
    width: 16rem;
  }

  .sm\:w-8 {
    width: 2rem;
  }

  .sm\:w-\[20\%\] {
    width: 20%;
  }

  .sm\:w-\[30vw\] {
    width: 30vw;
  }

  .sm\:w-\[340px\] {
    width: 340px;
  }

  .sm\:w-\[360px\] {
    width: 360px;
  }

  .sm\:w-\[40\%\] {
    width: 40%;
  }

  .sm\:w-\[60\%\] {
    width: 60%;
  }

  .sm\:w-\[63\%\] {
    width: 63%;
  }

  .sm\:w-\[73\%\] {
    width: 73%;
  }

  .sm\:w-\[80\%\] {
    width: 80%;
  }

  .sm\:w-\[81\%\] {
    width: 81%;
  }

  .sm\:w-full {
    width: 100%;
  }

  .sm\:min-w-\[239px\] {
    min-width: 239px;
  }

  .sm\:min-w-\[72vw\] {
    min-width: 72vw;
  }

  .sm\:min-w-\[920px\] {
    min-width: 920px;
  }

  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .sm\:justify-center {
    justify-content: center;
  }

  .sm\:gap-0 {
    gap: 0;
  }

  .sm\:gap-3 {
    gap: .75rem;
  }

  .sm\:gap-\[4px\] {
    gap: 4px;
  }

  .sm\:p-0 {
    padding: 0;
  }

  .sm\:p-5 {
    padding: 1.25rem;
  }

  .sm\:p-8 {
    padding: 2rem;
  }

  .sm\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .sm\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .sm\:text-\[12rem\] {
    font-size: 12rem;
  }

  .sm\:text-\[15px\] {
    font-size: 15px;
  }

  .sm\:text-\[16px\] {
    font-size: 16px;
  }

  .sm\:text-\[18px\] {
    font-size: 18px;
  }

  .sm\:text-\[20px\] {
    font-size: 20px;
  }

  .sm\:text-\[22px\] {
    font-size: 22px;
  }

  .sm\:text-\[2rem\] {
    font-size: 2rem;
  }

  .sm\:text-\[50px\] {
    font-size: 50px;
  }

  .sm\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}

@media (width >= 768px) {
  .md\:left-0 {
    left: 0;
  }

  .md\:left-\[34\%\] {
    left: 34%;
  }

  .md\:mr-0 {
    margin-right: 0;
  }

  .md\:mt-0 {
    margin-top: 0;
  }

  .md\:mt-\[-80px\] {
    margin-top: -80px;
  }

  .md\:block {
    display: block;
  }

  .md\:h-\[35px\] {
    height: 35px;
  }

  .md\:h-\[60\%\] {
    height: 60%;
  }

  .md\:h-\[80\%\] {
    height: 80%;
  }

  .md\:h-full {
    height: 100%;
  }

  .md\:w-0 {
    width: 0;
  }

  .md\:w-72 {
    width: 18rem;
  }

  .md\:w-\[25\%\] {
    width: 25%;
  }

  .md\:w-\[70\%\] {
    width: 70%;
  }

  .md\:w-\[80\%\] {
    width: 80%;
  }

  .md\:w-\[8vw\] {
    width: 8vw;
  }

  .md\:scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:justify-normal {
    justify-content: normal;
  }

  .md\:justify-start {
    justify-content: flex-start;
  }

  .md\:text-\[12rem\] {
    font-size: 12rem;
  }

  .md\:text-\[30px\] {
    font-size: 30px;
  }
}

@media (width >= 1024px) {
  .lg\:left-0 {
    left: 0;
  }

  .lg\:mt-\[-72px\] {
    margin-top: -72px;
  }

  .lg\:w-\[97\%\] {
    width: 97%;
  }

  .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .lg\:text-\[40px\] {
    font-size: 40px;
  }
}

@media (width >= 1280px) {
  .xl\:mt-\[-72px\] {
    margin-top: -72px;
  }
}

@media (width >= 1536px) {
  .\32 xl\:relative {
    position: relative;
  }

  .\32 xl\:bottom-\[8\%\] {
    bottom: 8%;
  }

  .\32 xl\:left-\[2\%\] {
    left: 2%;
  }

  .\32 xl\:h-\[15vh\] {
    height: 15vh;
  }

  .\32 xl\:w-full {
    width: 100%;
  }

  .\32 xl\:min-w-full {
    min-width: 100%;
  }

  .\32 xl\:gap-5 {
    gap: 1.25rem;
  }

  .\32 xl\:text-\[7vw\] {
    font-size: 7vw;
  }
}
/*# sourceMappingURL=index.f3514bb2.css.map */
