@font-face {
  font-family: "funkRocker-regular";
  src: url("./assets/fonts/funkrocker.regular.otf");
}
@font-face {
  font-family: "raleWay-regular";
  src: url("./assets/fonts/Raleway-Regular.ttf");
}
@font-face {
  font-family: "sfPro-bold";
  src: url("./assets/fonts/SFProDisplay-Bold.ttf");
}
@font-face {
  font-family: "sfPro-regular";
  src: url("./assets/fonts/SFProDisplay-Regular.ttf");
}
@font-face {
  font-family: "sfPro-medium";
  src: url("./assets/fonts/sfprodisplaymedium.OTF");
}
@font-face {
  font-family: "showGathic-regular";
  src: url("./assets/fonts/Showcargothic.ttf");
}

.noScrollbar::-webkit-scrollbar {
  display: none;
}
.noScrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

#tap::placeholder {
  color: white;
  opacity: .6;
}
#tap::-ms-input-placeholder {
  color: white;
}
#tap::-ms-input-placeholder {
  color: white;
}

.inputbase {
  line-height: 0 !important;
}

img {
  object-fit: contain;
}

@layer textTyping {
  .dynamic-txt {
    font-size: 50px;
    height: 70px;
    line-height: 70px;
    overflow: hidden;
  }
  .dynamic-txt > li {
    list-style: none;
    position: relative;
    animation: slide 6s steps(2) infinite;
  }
  @keyframes slide {
    100% {
      top: -70px;
    }
  }
  .dynamic-txt li span::after {
    position: absolute;
    content: "|";
    left: 0;
    height: 100%;
    width: 100%;
    line-height: 70px;
    background: #141531;
    animation: typing 6s steps(10) infinite;
  }
  @keyframes typing {
    40%,
    60% {
      left: calc(100% + 30px);
    }
    100% {
      left: 0;
    }
  }
}
.scrollbar-hide::-webkit-scrollbar {
  display: none; /* Hide scrollbar for WebKit browsers (Chrome, Safari) */
}

/* Hide scrollbar for Firefox */
.scrollbar-hide {
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  left: 43%;
  position: absolute;
  height: 45vh;
}

.loader {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #ffa91a;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.textareas {
  color: red;
}
.textareas::placeholder {
  color: red;
  content: "here"; /* Firefox */
}
textarea::placeholder {
  color: white;
}

.drop-shadow img {
  filter: drop-shadow(0px 0px 5px #ffa91a);
}

.nextbtn:hover {
  box-shadow: (0px 0px 10px 5px #e3a031);
}

.burn-start-btn {
  animation: zoom-in-zoom-out 3s ease infinite;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.2, 1.2);
  }
  100% {
    transform: scale(1, 1);
  }
}


.cursor-animation {
  opacity: 1;
  animation: cursor-animation .5s linear infinite;
}
@keyframes cursor-animation {
  0%{
    opacity: 0;
  }
  /* 50%{
    opacity: 0.5;
  } */
  100%{
    opacity: 1;
  }
}
.cursor-animation::after{
  content: " |";
}


@keyframes zoomInFromBack {
  0% {
    opacity: 0;
    transform: translateZ(-2000px) scale(0.1);
  }
  100% {
    opacity: 1;
    transform: translateZ(0) scale(1);
  }
}

.hiddenStage {
  opacity: 0;
  transform: translateZ(-2000px) scale(0.1);
}

.animate-zoom-in {
  animation: zoomInFromBack 3s ease-in-out forwards;
}

